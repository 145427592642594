import {keyframes} from "styled-components"

const slideInStart = {
  down: "transform: translate3d(0, -40px, 0);",
  left: "transform: translate3d(-40px, 0, 0);",
  right: "transform: translate3d(40px, 0, 0);",
  up: "transform: translate3d(0, 40px, 0);"
}

const slideIn = direction => keyframes`
  0% {
    ${slideInStart[direction]};
    opacity: 0;
  }  
  50% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    opacity: 1;
  }  
`

export const slideInDown = slideIn("down")
export const slideInLeft = slideIn("left")
export const slideInRight = slideIn("right")
export const slideInUp = slideIn("up")

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;    
  }  
`
