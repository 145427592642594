import styled from "styled-components"
import {defaultProps} from "styles/system/shared"

const Img = styled.img`
  ${defaultProps};
  display: flex;
`

Img.displayName = "Img"
Img.defaultProps = {
  alt: ""
}

export default Img
