import React, {memo} from "react"
import Primitives from "primitives"
import {AppParticles} from "styles/shared"
import particles from "styles/particles/stars"
import {componentRefPropType} from "lib/prop-types"

import About from "./about"
import TitleCard from "./title-card"

const propTypes = {
  componentRef: componentRefPropType
}

function Home({
  aboutRef,
  componentRef,
  height,
  scrollToAbout,
  width,
  ...props
}) {
  return (
    <Primitives.Flex
      ref={componentRef}
      flexDirection="column"
      width={1}
      {...props}
    >
      <Primitives.Flex
        alignItems="center"
        height={height}
        justifyContent="center"
        width={width}
      >
        <Primitives.Flex
          as="section"
          minHeight={300}
          position="absolute"
          zIndex={1}
        >
          <TitleCard onLearnMoreClick={scrollToAbout} />
        </Primitives.Flex>
        <AppParticles
          height={height}
          minHeight="100vh"
          minWidth="100vw"
          params={particles}
          width={width}
          zIndex={-1}
        />
      </Primitives.Flex>
      <Primitives.Flex
        backgroundColor="grey.200"
        flexDirection="column"
        width={1}
      >
        <About componentRef={aboutRef} height={height} width={1} />
      </Primitives.Flex>
    </Primitives.Flex>
  )
}

Home.propTypes = propTypes

export default memo(Home)
