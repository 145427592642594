import React, {memo} from "react"
import {contentPaddingX, footerHeight} from "lib/constants"
import Primitives from "primitives"

import Social from "./social"

// TODO: implement
const propTypes = {}

/**
 * Currently configured to show only when the user scrolls down.
 * @returns {*}
 * @constructor
 */
function FooterComponent() {
  return (
    <Primitives.Flex
      alignItems="center"
      backgroundColor="grey.200"
      borderTop="solid 1px"
      borderTopColor="grey.400"
      height={footerHeight}
      justifyContent="center"
      px={contentPaddingX}
      width={1}
    >
      <Social />
    </Primitives.Flex>
  )
}

FooterComponent.propTypes = propTypes

export default memo(FooterComponent)
