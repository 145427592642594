import React from "react"
import {number, oneOf, oneOfType, string} from "prop-types"

import HorizontalSeparator from "./horizontal-separator"
import VerticalSeparator from "./vertical-separator"

const propTypes = {
  size: oneOfType([number, string]).isRequired,
  type: oneOf(["horizontal", "vertical"]).isRequired
}

function SeparatorContainer({type, ...props}) {
  return type === "horizontal" ? (
    <HorizontalSeparator {...props} />
  ) : (
    <VerticalSeparator {...props} />
  )
}

SeparatorContainer.propTypes = propTypes

export default SeparatorContainer
