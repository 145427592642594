import {useCallback, useLayoutEffect} from "react"
import throttle from "lodash/throttle"
import {useDispatch} from "react-redux"
import {setShowingModal} from "redux/actions"

const isEscapeKey = ({code, key, keyCode}) =>
  code === "Escape" || key === "Escape" || keyCode === 27

function useWindowListeners() {
  const dispatch = useDispatch()
  const onKeyUp = useCallback(
    event => {
      if (isEscapeKey(event)) {
        dispatch({type: setShowingModal, value: false})
      }
    },
    [dispatch]
  )

  const keyUpThrottled = throttle(onKeyUp, 500)

  useLayoutEffect(() => {
    if (window) {
      window.addEventListener("keyup", keyUpThrottled)
      return () => {
        window.removeEventListener("keyup", keyUpThrottled)
      }
    }
  }, [keyUpThrottled])
}

export default useWindowListeners
