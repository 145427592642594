import {createElement, useMemo} from "react"
import {is, length} from "ramda"
import {useSelector} from "react-redux"
import {getBreakpoint} from "redux/selectors/ui"

import {H1, H2, H3, H4, H5, H6} from "./headers"
import {Label, P, Span} from "./body"

const tagMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  label: Label,
  p: P,
  span: Span
}

const getResponsiveTag = (tag, breakpointIndex) => {
  const arrSize = length(tag)

  if (arrSize - 1 <= breakpointIndex) {
    return tagMap[tag[arrSize - 1]]
  }

  return tagMap[tag[breakpointIndex]]
}

function Text({tag, variant, ...props}) {
  const breakpointIndex = useSelector(getBreakpoint)
  const element = useMemo(
    () =>
      is(Array, tag) ? getResponsiveTag(tag, breakpointIndex) : tagMap[tag],
    [breakpointIndex, tag]
  )
  return createElement(element, {variant, ...props})
}

export default Text
