import React, {Fragment} from "react"
import {func, node, oneOfType} from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import {MuiThemeProvider, StylesProvider} from "@material-ui/core/styles"
import {ThemeProvider as StyledThemeProvider} from "styled-components"
import GlobalStyle from "styles/global"
import {muiTheme, styledSystemTheme} from "styles/theme"
import {locationPropType} from "lib/prop-types"

import PageWrapper from "./page-wrapper"

const propTypes = {
  page: oneOfType([func, node]).isRequired,
  location: locationPropType
}

/**
 * Performs similarly to App.js
 * @param props
 * @returns {*}
 * @constructor
 */
function AppRootContainer(props) {
  return (
    <StylesProvider>
      <CssBaseline />
      <StyledThemeProvider theme={styledSystemTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <Fragment>
            <PageWrapper {...props} />
            <GlobalStyle />
          </Fragment>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  )
}

AppRootContainer.propTypes = propTypes

export default AppRootContainer
