import React, {memo} from "react"
import Primitives from "primitives"
import {bool} from "prop-types"
import {stringPropType, stringOrNumberPropType} from "lib/prop-types"
import {defaultTransition} from "styles/variables"

const propTypes = {
  hovered: bool,
  color: stringPropType,
  size: stringOrNumberPropType
}

function SocialIconComponent({
  borderRadius = 5,
  color = "grey.300",
  hovered,
  hoverColor = "white",
  hoverRef,
  icon,
  size = 38,
  ...props
}) {
  return (
    <Primitives.Flex
      ref={hoverRef}
      alignItems="center"
      cursor="pointer"
      height={size}
      justifyContent="center"
      width={size}
      {...props}
    >
      <Primitives.Icon
        backgroundColor={hovered ? "primary" : "transparent"}
        borderRadius={borderRadius}
        color={hovered ? hoverColor : color}
        icon={icon}
        size={size}
        transition={`color ${defaultTransition}, background-color ${defaultTransition}`}
      />
    </Primitives.Flex>
  )
}

SocialIconComponent.propTypes = propTypes

export default memo(SocialIconComponent)
