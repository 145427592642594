import React from "react"
import {css} from "styled-components"
import {ArrowRight} from "@styled-icons/typicons/ArrowRight"
import Primitives from "primitives"
import {animationCss} from "styles/animations"
import {
  defaultTransition,
  fadeIn,
  slideInDown,
  slideInLeft
} from "styles/variables"

// TODO: implement
const propTypes = {}

const buttonCss = css`
  svg {
    transition: transform ${defaultTransition};
  }
  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }
`

function TitleCardComponent({onLearnMoreClick, ...props}) {
  return (
    <Primitives.Flex alignItems="center" flexDirection="column" {...props}>
      <Primitives.Flex justifyContent="center" mb={3}>
        <Primitives.Flex color="white" fontWeight={0}>
          <Primitives.Text
            color="inherit"
            css={animationCss(slideInDown, 2, 1, "forwards")}
            opacity={0}
            tag="h1"
          >
            Hello, my name is
          </Primitives.Text>
          <Primitives.Text color="instagram" ml={[1, 2]} tag="h1">
            Ryan Bower
          </Primitives.Text>
        </Primitives.Flex>
      </Primitives.Flex>
      <Primitives.Flex
        css={animationCss(slideInLeft, 2, 2.5, "forwards")}
        justifyContent="center"
        mb={4}
        opacity={0}
      >
        <Primitives.Text color="white" tag="h1">
          I'm a Full-Stack Web Developer
        </Primitives.Text>
      </Primitives.Flex>
      <Primitives.Flex css={animationCss(fadeIn, 2, 4, "forwards")} opacity={0}>
        <Primitives.Button
          css={buttonCss}
          onClick={onLearnMoreClick}
          variant="outlined-icon-right"
        >
          <Primitives.Text color="white" tag="h4">
            Learn more
          </Primitives.Text>
          <Primitives.Flex position="relative">
            <Primitives.Icon
              color="white"
              icon={ArrowRight}
              ml="2px"
              mt="2px"
              position="absolute"
              size={24}
            />
          </Primitives.Flex>
        </Primitives.Button>
      </Primitives.Flex>
    </Primitives.Flex>
  )
}

TitleCardComponent.propTypes = propTypes

export default TitleCardComponent
