import styled from "styled-components"
import {flexbox} from "styled-system"
import {defaultProps} from "styles/system/shared"

import buttonVariants from "./variants"

const Button = styled.button`
  display: flex;
  transition: background-color 80ms ease-in;
  outline: none;
  /* Remove outline for non-keyboard :focus */
  *:focus:not(.focus-visible) {
    outline: none;
  }
  ${defaultProps};
  ${flexbox};
  ${buttonVariants};
`

Button.displayName = "Primitives.Button"

export default Button
