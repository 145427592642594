import {useCallback, useLayoutEffect, useRef, useState} from "react"
import throttle from "lodash/throttle"

function getDimensionObject(node) {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    innerHeight: window.innerHeight
  }
}

function useDimensions(resizeListener = true, throttleDelay = 50) {
  const [dimensions, setDimensions] = useState({})

  const ref = useRef(null)

  const measure = useCallback(() => {
    window.requestAnimationFrame(() =>
      setDimensions(getDimensionObject(ref.current))
    )
  }, [ref])

  // We need a reference to the debounced callback, but useEffect re-triggers
  // whenever one of its dependencies update.  This ref will only be
  // re-evaluated whenever the ref of the measure() callback changes.
  const measureThrottled = useRef(throttle(measure, throttleDelay)).current

  useLayoutEffect(() => {
    if (ref.current) {
      measureThrottled()
      if (resizeListener) {
        window.addEventListener("resize", measureThrottled)

        return () => {
          window.removeEventListener("resize", measureThrottled)
        }
      }
    }
  }, [measureThrottled, resizeListener])

  return [ref, dimensions]
}

export default useDimensions
