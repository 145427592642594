import Box from "./box"
import Button from "./button"
import Flex from "./flex"
import Icon from "./icon"
import Img from "./img"
import Input from "./input"
import Link from "./link"
import Text from "./text"

export default {Box, Button, Flex, Icon, Img, Input, Link, Text}
