import {css} from "styled-components"
import {
  background,
  border,
  color,
  compose,
  layout,
  position,
  shadow,
  space
} from "styled-system"

import customProps from "./custom"

export const defaultProps = css`
  ${compose(background, border, color, layout, position, shadow, space)}
  ${customProps};
`
