import React, {Fragment} from "react"
import styled from "styled-components"
import Primitives from "primitives"
import {Footer, Modal} from "components"
import {
  componentRefPropType,
  fcPropType,
  locationPropType
} from "lib/prop-types"
import {defaultPageWrapperProps} from "lib/constants"

const AppStyle = styled(Primitives.Flex)`
  min-height: 100vh;
  overflow-x: hidden;
`

const propTypes = {
  location: locationPropType,
  page: fcPropType.isRequired,
  resizeRef: componentRefPropType
}

function PageWrapperComponent({
  backgroundColor,
  page: Page,
  pageWrapperProps = defaultPageWrapperProps,
  resizeRef,
  ...props
}) {
  return (
    <AppStyle ref={resizeRef} backgroundColor={backgroundColor} width={1}>
      <Fragment>
        <Primitives.Flex
          flexDirection="column"
          height="100%"
          minHeight="100vh"
          width={1}
        >
          <Modal />
          <Primitives.Flex
            flexGrow={1}
            height="100%"
            width={1}
            {...pageWrapperProps}
          >
            <Page {...props} />
          </Primitives.Flex>
          <Footer />
        </Primitives.Flex>
      </Fragment>
    </AppStyle>
  )
}

PageWrapperComponent.propTypes = propTypes

export default PageWrapperComponent
