import styled from "styled-components"
import {typography} from "styled-system"
import {defaultProps} from "styles/system/shared"

const Input = styled.input`
  ${typography};
  ${defaultProps};
  outline: none;
`

Input.displayName = "Primitives.Input"

export default Input
