import styled from "styled-components"

import {textProps} from "./shared"

export const H1 = styled.h1`
  ${textProps};
`

H1.defaultProps = {
  color: "text",
  fontWeight: 1,
  fontSize: [3, 5, 6],
  lineHeight: [3, 5, 6],
  letterSpacing: [0.3, 0]
}

export const H2 = styled.h2`
  ${textProps};
`

H2.defaultProps = {
  color: "text",
  fontSize: 5,
  fontWeight: 1,
  letterSpacing: 0,
  lineHeight: 5
}

export const H3 = styled.h3`
  ${textProps};
`

H3.defaultProps = {
  color: "text",
  fontSize: 4,
  fontWeight: 1,
  letterSpacing: 0.3,
  lineHeight: 4
}

export const H4 = styled.h4`
  ${textProps};
`

H4.defaultProps = {
  color: "text",
  fontSize: [2, 3],
  fontWeight: 1,
  letterSpacing: 0.3,
  lineHeight: 4
}

export const H5 = styled.h5`
  ${textProps};
`

H5.defaultProps = {
  color: "text",
  fontSize: 2,
  fontWeight: 1,
  letterSpacing: 0.3,
  lineHeight: 2
}

export const H6 = styled.h6`
  ${textProps};
`

H6.defaultProps = {
  color: "text",
  fontSize: 1,
  fontWeight: 1,
  letterSpacing: 0.25,
  lineHeight: 1
}
