import {object, shape, string} from "prop-types"

export const locationPropType = shape({
  hash: string,
  href: string,
  host: string,
  hostname: string,
  origin: string,
  pathname: string,
  port: string,
  protocol: string,
  search: string
})

export const componentRefPropType = shape({
  // a React functional component
  current: object
})
