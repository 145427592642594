import React from "react"
import styled from "styled-components"
import {elementType, number, oneOfType, string} from "prop-types"
import {defaultProps} from "styles/system/shared"

const IconBase = styled.div`
  ${defaultProps};
`

const propTypes = {
  icon: elementType.isRequired,
  size: oneOfType([number, string]).isRequired,
  title: string
}

const iconStyle = {
  color: "inherit"
}

function Icon({icon: RenderIcon, size, title, ...props}) {
  return (
    <IconBase {...props}>
      <RenderIcon size={size} style={iconStyle} title={title} />
    </IconBase>
  )
}

Icon.propTypes = propTypes
Icon.displayName = "Primitives.Icon"

export default Icon
