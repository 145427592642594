import React, {useCallback} from "react"
import {useDispatch, useSelector} from "react-redux"
import {getIsShowingModal, getModalContent} from "redux/selectors/ui"
import {setShowingModal} from "redux/actions"

import ModalComponent from "./ModalComponent"

function ModalContainer() {
  const dispatch = useDispatch()
  const showingModal = useSelector(getIsShowingModal)
  const modalContent = useSelector(getModalContent)

  const onCloseClick = useCallback(() => {
    if (showingModal) {
      dispatch({type: setShowingModal, value: false})
    }
  }, [dispatch, showingModal])

  return (
    <ModalComponent
      onClose={onCloseClick}
      render={modalContent}
      showing={showingModal}
    />
  )
}

export default ModalContainer
