import React, {createElement, forwardRef, memo} from "react"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import {bool, func, node, oneOfType} from "prop-types"

const propTypes = {
  onClose: func.isRequired,
  render: oneOfType([func, node]),
  showing: bool.isRequired
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} direction="up" {...props} />
})

function ModalComponent({onClose, render, showing}) {
  return (
    <Dialog
      aria-describedby="modal-description"
      aria-labelledby="modal-title"
      open={showing}
      TransitionComponent={Transition}
    >
      {render ? createElement(render, {onClose}) : null}
    </Dialog>
  )
}

ModalComponent.propTypes = propTypes

export default memo(ModalComponent)
