import React, {memo} from "react"
import Primitives from "primitives"
import {underConstruction} from "assets/images/svg"

// TODO: implement
const propTypes = {}

function AboutComponent({componentRef, ...props}) {
  return (
    <Primitives.Flex
      ref={componentRef}
      alignItems="center"
      flexDirection="column"
      py={5}
      width={1}
      {...props}
    >
      <Primitives.Flex justifyContent="center" width={1}>
        <Primitives.Text mb={4} tag="h1">
          About
        </Primitives.Text>
      </Primitives.Flex>
      <Primitives.Img
        borderRadius={60}
        height="300px"
        src={underConstruction}
      />
      <Primitives.Text tag="h4">Coming soon&#8482;</Primitives.Text>
    </Primitives.Flex>
  )
}

AboutComponent.propTypes = propTypes

export default memo(AboutComponent)
