import React, {memo} from "react"
import Primitives from "primitives"
import {Facebook} from "@styled-icons/boxicons-logos/Facebook"
import {Twitter} from "@styled-icons/boxicons-logos/Twitter"
import {Instagram} from "@styled-icons/boxicons-logos/Instagram"

import SocialIcon from "./social-icon"

// TODO: implement
const propTypes = {}

function SocialComponent() {
  return (
    <Primitives.Flex justifyContent="space-between" width={170}>
      <SocialIcon color="facebook" icon={Facebook} />
      <SocialIcon color="twitter" icon={Twitter} />
      <SocialIcon color="instagram" icon={Instagram} />
    </Primitives.Flex>
  )
}

SocialComponent.propTypes = propTypes

export default memo(SocialComponent)
