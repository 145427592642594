/**
 * Provides re-usable prop-types for styling props based on this app's
 * implementation of styled-system.  Props have both array and individual value
 * options to match styled-system's responsive capabilities.
 */
import {arrayOf, number, object, oneOfType, shape, string} from "prop-types"

export const stringOrNumberPropType = oneOfType([
  arrayOf(oneOfType([number, string])),
  number,
  string
])

export const stringPropType = oneOfType([arrayOf(string), string])

// TODO: implement
export const styleObjectPropType = shape({})

export const objectPropType = oneOfType([arrayOf(object), object])
