import React from "react"
import useResizeListener from "lib/hooks/use-resize-listener"
import useWindowListeners from "lib/hooks/use-window-listeners"
import {fcPropType} from "lib/prop-types"

import PageWrapperComponent from "./PageWrapperComponent"

const propTypes = {
  page: fcPropType.isRequired
}

function PageWrapperContainer({page, ...props}) {
  const [ref] = useResizeListener()
  useWindowListeners()

  return <PageWrapperComponent page={page} resizeRef={ref} {...props} />
}

PageWrapperContainer.propTypes = propTypes

export default PageWrapperContainer
