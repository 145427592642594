import {css} from "styled-components"

/**
 *
 * @param keyframes {FlattenSimpleInterpolation} styled-components keyframes
 * @param duration {number}
 * @param delay {number}
 * @param fillMode {string}
 * @returns {FlattenSimpleInterpolation}
 */
export const animationCss = (keyframes, duration, delay, fillMode) => css`
  animation: ${keyframes} ${duration}s ${delay}s ${fillMode};
`
