export const contentPaddingX = [4, 5, 7]
export const contentPaddingBottom = 3
export const contentPaddingTop = 4
export const navbarBreadcrumbHeight = 42
export const navbarHeight = [56, 56, 72]
export const footerHeight = 56

export const defaultPageWrapperProps = {
  pb: contentPaddingBottom,
  pt: contentPaddingTop,
  px: contentPaddingX
}
