import React, {memo} from "react"
import Primitives from "primitives"

// TODO: implement
const propTypes = {}

function HorizontalSeparatorComponent({color, size, ...props}) {
  return (
    <Primitives.Flex alignItems="center" flexGrow={1} {...props}>
      <Primitives.Flex backgroundColor={color} flexGrow={1} height={size} />
    </Primitives.Flex>
  )
}

HorizontalSeparatorComponent.propTypes = propTypes

export default memo(HorizontalSeparatorComponent)
