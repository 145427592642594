import {createSelector} from "reselect"
import {identity, pathOr} from "ramda"
import {breakpoints} from "styles/theme"

const stateKey = "ui"

export const getBreakpoint = createSelector(
  pathOr(0, [stateKey, "breakpoint"]),
  identity
)
export const getDrawerContent = createSelector(
  pathOr(null, [stateKey, "drawerContent"]),
  identity
)
export const getFocusedElement = createSelector(
  pathOr(null, [stateKey, "focusedElement"]),
  identity
)
export const getMobileFooterContent = createSelector(
  pathOr(null, [stateKey, "mobileFooterContent"]),
  identity
)
export const getModalContent = createSelector(
  pathOr(null, [stateKey, "modalContent"]),
  identity
)
export const getIsMobile = createSelector(
  pathOr(null, [stateKey, "viewportWidth"]),
  width => width && width < breakpoints[1] && width > -1
)
export const getIsShowingDrawer = createSelector(
  pathOr(false, [stateKey, "showingDrawer"]),
  identity
)
export const getIsShowingModal = createSelector(
  pathOr(false, [stateKey, "showingModal"]),
  identity
)
