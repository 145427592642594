import {createGlobalStyle, css} from "styled-components"
import {NoSelect} from "styles/helpers"
import {fontFamilyBody, fontFamilyHeader} from "lib/config"

import colors from "./colors"

export const defaultFontBody = css`
  font-family: ${fontFamilyBody};
  font-weight: 500;
  color: ${colors["text"]};
`

export const defaultFontHeader = css`
  font-family: ${fontFamilyHeader};
  font-weight: 400;
  color: ${colors.text.main};
`

const GlobalStyles = createGlobalStyle`
  body {
    padding: 0 !important;
    margin: 0 !important;
    ${defaultFontBody};
  }
  p {
    ${defaultFontBody};
  }
  h1, h2, h3, h4, h5, h6 {
    ${defaultFontHeader};
  }
  input {
    padding: 0;
    ${defaultFontBody};
    ::placeholder {
      ${NoSelect};
    }
  }
  button, div, span, td {
    ${defaultFontBody}
  }
  ul {
    margin-bottom: 0;
    margin-top: 0;
  }
`

export default GlobalStyles
