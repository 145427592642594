/**
 * Provides prop-types for data returned by this app's hooks, where applicable.
 **/
import {arrayOf, number, oneOfType, shape, string} from "prop-types"

export const windowDimensionsPropType = shape({
  bottom: number,
  height: number,
  left: number,
  right: number,
  top: number,
  width: number,
  x: number,
  y: number
})
