import styled from "styled-components"
import {flexbox} from "styled-system"
import {defaultProps} from "styles/system/shared"

const Flex = styled.div`
  ${flexbox};
  ${defaultProps};
  display: flex;
`

Flex.displayName = "Primitives.Flex"

export default Flex
