import React, {useCallback} from "react"

import NotFoundComponent from "./NotFoundComponent"

function NotFoundContainer({location, navigate, pathname, ...props}) {
  const onBackClick = useCallback(() => {
    navigate(location.origin)
  }, [location, navigate])

  return (
    <NotFoundComponent
      onBackClick={onBackClick}
      pathname={pathname}
      {...props}
    />
  )
}

export default NotFoundContainer
