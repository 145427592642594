import React, {memo} from "react"
import Primitives from "primitives"

// TODO: Implement
const propTypes = {}

function VerticalSeparatorComponent() {
  return <Primitives.Flex>TODO: IMPLEMENT</Primitives.Flex>
}

VerticalSeparatorComponent.propTypes = propTypes

export default memo(VerticalSeparatorComponent)
