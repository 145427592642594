import React, {useCallback, useMemo, useRef} from "react"
import useDimensions from "lib/hooks/use-dimensions"

import HomeComponent from "./HomeComponent"

function HomeContainer(props) {
  const [ref, dimensions] = useDimensions()
  const aboutRef = useRef(null)

  const {innerHeight, width} = useMemo(() => dimensions, [dimensions])

  const scrollToAbout = useCallback(() => {
    if (aboutRef && aboutRef.current) {
      aboutRef.current.scrollIntoView({behavior: "smooth"})
    }
  }, [aboutRef])

  return (
    <HomeComponent
      aboutRef={aboutRef}
      componentRef={ref}
      height={innerHeight}
      scrollToAbout={scrollToAbout}
      width={width}
      {...props}
    />
  )
}

export default HomeContainer
