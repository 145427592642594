import styled from "styled-components"
import {variant} from "styled-system"

import {textProps} from "./shared"

const variants = variant({
  variants: {
    subtitle: {
      color: "text-light",
      fontSize: 1,
      lineHeight: "20px",
      letterSpacing: 0.25
    },
    small: {
      fontSize: 1,
      lineHeight: "20px",
      letterSpacing: 0.25
    }
  }
})

export const P = styled.p`
  ${textProps};
  ${variants};
`

export const Span = styled.span`
  ${textProps};
  ${variants};
`

export const Label = styled.label`
  ${textProps};
`

const defaultBodyProps = {
  color: "text",
  fontSize: 2,
  fontWeight: 1,
  letterSpacing: 0.25,
  lineHeight: 2
}

P.defaultProps = defaultBodyProps
Span.defaultProps = defaultBodyProps
