import {is} from "ramda"
import {system} from "styled-system"

const pixelSizeTransformer = value => {
  if (value > 0 && value <= 1) {
    return `${(value * 100).toFixed(1)}%`
  }
  if ((is(Number, value) && value > 1) || value === 0) {
    return `${value}px`
  }
  return value
}

export default system({
  cursor: true,
  fill: {
    property: "fill",
    scale: "colors"
  },
  height: {
    property: "height",
    transform: pixelSizeTransformer
  },
  listStyle: true,
  maxWidth: {
    property: "maxWidth",
    transform: pixelSizeTransformer
  },
  textDecoration: true,
  transition: {
    property: "transition"
  },
  transform: true,
  userSelect: {
    property: "userSelect"
  }
})
