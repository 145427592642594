import React, {memo} from "react"
import {func, node, oneOfType} from "prop-types"
import Primitives from "primitives"

const propTypes = {children: oneOfType([func, node]).isRequired}

/**
 * A component that provides a universal style and layout for modal content.
 * Modal components should utilize this component.
 * @param children {object} - React JSX
 * @param description {string}
 * @param title {string}
 * @param props
 * @returns {*}
 * @constructor
 */
function ModalBodyComponent({children, description, title, ...props}) {
  return (
    <Primitives.Flex flexDirection="column" {...props}>
      <Primitives.Text id="modal-title" mb={1} tag="h4" {...props}>
        {title}
      </Primitives.Text>
      <Primitives.Text id="modal-description" mb={1} tag="h6">
        {description}
      </Primitives.Text>
      {children}
    </Primitives.Flex>
  )
}

ModalBodyComponent.propTypes = propTypes

export default memo(ModalBodyComponent)
