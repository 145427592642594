import {defaultTransition} from "styles/variables"

const outlined = {
  alignContent: "center",
  backgroundColor: "transparent",
  border: "solid 2px",
  borderColor: "white",
  borderRadius: 1,
  color: "white",
  cursor: "pointer",
  justifyContent: "center",
  transition: `border-color ${defaultTransition}, background-color ${defaultTransition}, transform ${defaultTransition}`,
  "&:hover": {
    backgroundColor: "indigo.500",
    borderColor: "indigo.500"
  }
}

const buttonVariants = {
  outlined: {
    ...outlined,
    px: 4,
    py: [0, 1, 2]
  },
  "outlined-icon-right": {
    ...outlined,
    pr: 5,
    pl: 3,
    py: [0, 1, 2]
  }
}

export default buttonVariants
