import styled from "styled-components"
import {defaultProps} from "styles/system/shared"

const Box = styled.div`
  box-sizing: border-box;
  ${defaultProps};
`

Box.displayName = "Primitives.Box"

export default Box
