import {graphql, useStaticQuery} from "gatsby"

const siteMetadataQuery = graphql`
  query SiteMetadata {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        pathPrefix
        siteUrl
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultImage: image
        siteLanguage
        headline
        author
      }
    }
  }
`

function useSiteMetadata() {
  const {site} = useStaticQuery(siteMetadataQuery)
  const {buildTime, siteMetadata} = site
  return {buildTime, ...siteMetadata}
}

export default useSiteMetadata
