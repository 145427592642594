import React from "react"
import {string} from "prop-types"
import styled from "styled-components"
import {Link as GatsbyLink} from "gatsby"
import {flexbox} from "styled-system"
import {defaultProps} from "styles/system/shared"

const LinkStyled = styled(GatsbyLink)`
  display: flex;
  text-decoration: none;
  ${defaultProps};
  ${flexbox};
`

const propTypes = {
  to: string.isRequired
}

function Link({to, ...props}) {
  return <LinkStyled to={to} {...props} />
}

Link.propTypes = propTypes

export default Link
