import {useEffect, useRef} from "react"
import {useDispatch} from "react-redux"
import {setViewportWidth} from "redux/actions"
import throttle from "lodash/throttle"
import useDimensions from "lib/hooks/use-dimensions"

/**
 * A hook that responds to a change in the viewport width of the app's window.
 */
function useResizeListener() {
  const [ref, {width}] = useDimensions()
  const dispatch = useDispatch()

  const setWidth = viewportWidth => {
    // Update the global store with the value of the current viewportWidth
    dispatch({type: setViewportWidth, value: viewportWidth})
  }

  const setViewportWidthThrottled = useRef(throttle(setWidth, 200)).current

  useEffect(() => {
    setViewportWidthThrottled(width)
  }, [setViewportWidthThrottled, width])

  return [ref]
}

export default useResizeListener
