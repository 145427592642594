import {variant} from "styled-system"

import filled from "./filled"
import outlined from "./outlined"

const variants = variant({
  variants: {
    ...filled, ...outlined
  }
})

export default variants
