import React from "react"
import useHover from "lib/hooks/use-hover"

import SocialIconComponent from "./SocialIconComponent"

function SocialIconContainer({...props}) {
  const [ref, hovered] = useHover(false)

  return <SocialIconComponent hovered={hovered} hoverRef={ref} {...props} />
}

export default SocialIconContainer
