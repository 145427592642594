import React, {memo} from "react"
import Primitives from "primitives"
import {error404} from "assets/images/svg"

// TODO: implement
const propTypes = {}

function NotFoundComponent({onBackClick, ...props}) {
  return (
    <Primitives.Flex
      alignItems="center"
      flexDirection="column"
      p={3}
      width={1}
      {...props}
    >
      <Primitives.Flex mb={4}>
        <Primitives.Img height="250px" src={error404} />
      </Primitives.Flex>
      <Primitives.Text mb={5} tag="h4">
        You've navigated to a page that doesn't exist
      </Primitives.Text>
      <Primitives.Button onClick={onBackClick} variant="filled" width={250}>
        Go Back
      </Primitives.Button>
    </Primitives.Flex>
  )
}

NotFoundComponent.propTypes = propTypes

export default memo(NotFoundComponent)
